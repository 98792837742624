import PageRequest from '../../../../PaginationHelper/Lib/PageRequest';
import SortingModelT from '../../../../Common/Models/SortingModelT';
import EnumSortingEndpoints from '../../../../AnalyticEndpoints/IServices/Models/EnumSortingEndpoints';

export default class EndpointsRequest  {
	ProjectLogGroupId?: number = null;
	FromDate: Date = null;
	ToDate: Date = null;
	Page: PageRequest = null;
	SortingModel: SortingModelT<EnumSortingEndpoints> = null;
	constructor(obj?: Partial<EndpointsRequest>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
