<template>
  <page :name="'Список методов'" :selectSource="false">
    <tirscript3-loading :loading="isLoading"></tirscript3-loading>
    <template v-slot:toolbar>
      <filter-component
          v-model:filter="filter"
          :selectInterval="false"
          :selectLogsFilter="false"
          :selectViewFilter="false"
          @filterData="filterData"
      ></filter-component>
    </template>
    <tirscript3-table
        :items="endpointsResponse"
        style="overflow-x: auto"
        template-columns="150px auto auto auto auto auto 70px 70px"
    >
      <template #header
      >
        <table-header-item
            v-for="(item, idx) in headerItemList" key="idx"
            :defaultSort="item.defaultSort"
            :isNotActiveSort="item.isNotActiveSort"
            :sortName="item.sortName"
            @onSort="sort"
        >
          {{ item.title }}
        </table-header-item>
      </template>

      <template #body="endpointsResponse">
        <tirscript3-table-body-item>{{
            endpointsResponse.item.EndpointId
          }}
        </tirscript3-table-body-item>
        <tirscript3-table-body-item>{{
            endpointsResponse.item.EndpointName
          }}
        </tirscript3-table-body-item>
        <tirscript3-table-body-item>{{
            endpointsResponse.item.NumberOfCalls
          }}
        </tirscript3-table-body-item>
        <tirscript3-table-body-item>{{
            endpointsResponse.item.AverageTime.toFixed(2)
          }}
        </tirscript3-table-body-item>
        <tirscript3-table-body-item>{{
            endpointsResponse.item.MaxTime
          }}
        </tirscript3-table-body-item>
        <tirscript3-table-body-item>{{
            endpointsResponse.item.MinTime
          }}
        </tirscript3-table-body-item>
        <tirscript3-table-body-item>
          <div class="table-icon">
            <router-link
                :to="{
                name: 'average-response-time',
                params: {
                  Id: endpointsResponse.item.EndpointId.toString(),
                  Name: endpointsResponse.item.EndpointName,
                  DateFrom: endpointsRequest.FromDate,
                  DateTo: endpointsRequest.ToDate
                },
              }"
                title="Среднее время отклика метода"
            ><i class="icon-moon icon-chart-arrow"></i
            ></router-link>
          </div
          >
        </tirscript3-table-body-item>
        <tirscript3-table-body-item
        >
          <div class="table-icon">
            <router-link
                :to="{
                name: 'users-statistic',
                params: {
                  Id: endpointsResponse.item.EndpointId.toString(),
                  Name: endpointsResponse.item.EndpointName,
                  DateFrom: endpointsRequest.FromDate,
                  DateTo: endpointsRequest.ToDate
                },
              }"
                title="Статистика по пользователям"
            ><i class="icon-moon icon-admin"></i
            ></router-link>
          </div
          >
        </tirscript3-table-body-item>
      </template>
    </tirscript3-table>
    <tirscript3-pagination
        v-show="pageResponse.Count > 0"
        ref="pagination"
        :count="pageResponse.Count"
        :size="pageResponse.Size"
        class="mt-2"
        @onChange="changePage"
    ></tirscript3-pagination>
    <!--Сообщение об ошибке-->
    <div
        v-if="!error.isSuccess"
        class="row mt-2 justify-content-center"
    >
      {{ error.errorMessage }}
    </div>
  </page>
</template>
<script lang="ts">
import {Options, Vue} from "vue-property-decorator";
import Page from "@views/page/components/page.vue";
import LogsViewFilter from "@/views/components/filter-component/logs-view-filter.vue";
import FilterModel from "@/views/page/components/filter-model";
import EndpointsResponse from "@/dataSource/api/AnalyticEndpoints/IServices/Models/Response/EndpointsResponse";
import EndpointsResponseModel
  from "@/dataSource/api/AnalyticEndpoints/IServices/Models/Response/EndpointsResponseModel";
import PageResponse from "@/dataSource/api/PaginationHelper/Lib/PageResponse";
import EnumSortingType from "@/dataSource/api/Common/Models/Enums/EnumSortingType";
import EndpointsRequest from "@/dataSource/api/AnalyticEndpoints/IServices/Models/Request/EndpointsRequest";
import getUTC from "@/function/getUTC"
import filter from '@/plugins/dataStore/Store'

@Options({
  name: "MethodList",
  components: {
    Page,
    LogsViewFilter,
  },
})
export default class MethodList extends Vue {
  filter: FilterModel = new FilterModel();
  endpointsResponse: Array<EndpointsResponseModel> = [];
  pageResponse: PageResponse = new PageResponse();
  endpointsRequest: EndpointsRequest = new EndpointsRequest();
  sortName: string = "AverageTime";
  sortType: EnumSortingType = 1;
  isLoading: boolean = false;

  error = {
    isSuccess: true,
    errorMessage: ""
  }

  headerItemList = [
    {
      title: "#",
      sortName: "",
      defaultSort: "",
      isNotActiveSort: true,
    },
    {
      title: "Название",
      sortName: "EndpointName",
      defaultSort: "",
      isNotActiveSort: true,
    },
    {
      title: "Кол-во раз вызова",
      sortName: "NumberOfCalls",
      defaultSort: "",
      isNotActiveSort: true,
    },
    {
      title: "Ср. t отклика",
      sortName: "AverageTime",
      defaultSort: "asc",
      isNotActiveSort: false,
    },
    {
      title: "Макс. t отклика",
      sortName: "MaxTime",
      defaultSort: "",
      isNotActiveSort: true,
    },
    {
      title: "Мин. t отклика",
      sortName: "MinTime",
      defaultSort: "",
      isNotActiveSort: true,
    },
    {
      title: "",
      sortName: "",
      defaultSort: "",
      isNotActiveSort: true,
    },
    {
      title: "",
      sortName: "",
      defaultSort: "",
      isNotActiveSort: true,
    }
  ]

  created() {
    this.filter = new FilterModel();
    this.filter.DateFrom = this.filter.getUTC(1);
  }

  async getData() {
    this.isLoading = true;
    let res: EndpointsResponse;
    this.endpointsRequest = {
      ProjectLogGroupId: this.filter.ProjectLogGroupId || null,
      FromDate: this.filter.DateFrom,
      ToDate: this.filter.DateTo,
      Page: {Skip: this.filter.SkipPage, Take: this.filter.TakePage},
      SortingModel: {Field: this.sortName, Type: this.sortType},
    };
    let tmpFilter = getUTC(this.endpointsRequest)
    try {
      res = await this.$api.AnalyticEndpointsService.getEndpointsAsync(
          tmpFilter
      );
    } catch (error) {
      this.error.isSuccess = false;
      this.error.errorMessage = error;
      this.isLoading = false;
      return;
    }
    this.endpointsResponse = res.Endpoints;
    this.pageResponse = res.Page;
    this.isLoading = false;
  }

  filterData() {
    this.filter.SkipPage = 0;
    (this.$refs.pagination as any).reset();
    this.getData();
  }

  changePage(page) {
    this.filter.SkipPage = (page - 1) * this.filter.TakePage;
    this.getData();
  }

  sort(name: string, type: number) {
    this.sortName = name;
    this.sortType = type;
    this.headerItemList.forEach(item => {
      item.isNotActiveSort = item.sortName !== name;
    })
    this.getData();
  }
}
</script>

<style lang="less">
.toolbar {
  display: flex;
  justify-content: space-between;

  .tirscript-dropdown {
    flex: 0 1 45%;
  }

  .date-wrapper {
    display: flex;
    flex: 0 1 45%;

    .tirscript-date-picker {
      flex: 0 1 50%;
    }
  }

  .tirscript-button {
    flex: 0 0 8%;
  }
}

.table-icon {
  width: 100%;
  display: flex;
  justify-content: center;

  .icon-admin::before {
    color: #586174;
  }
}
</style>